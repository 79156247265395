@import '../../config/globalColors.scss';

$hwd: css-min(250px, 25vw);


.video-metadata {
    color: #707070;
    font-size: 16px;

    h3 {
        font-weight: 400;
        color: $text-color;
        max-width: 70vw;
    }

    &-vid-title-full {
        max-width: 100% !important;
    }

    .video-stats {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .video-actions {
            display: flex;
            align-items: center;

            &>*:not(:last-child) {
                margin-right: 8px;
            }

            // &-input {
            //     // display: none;
            // }
        }
    }
}

#unique-btn {
    background-color: rgba(255, 255, 255, 0);
    border-width: 0;
    margin-right: -0.5vw;
    width: 0;
}

.mode-toggle-btn {
    @media screen and (max-width: 800px) {
        visibility: hidden;
    }
}

// Social Sharing
.pop-up {
    // &-container {
    //     position: fixed;
    //     height: 100%;
    //     width: 100%;
    //     background-color: #00000000;
    //     z-index: 151;
    //     top: 0;
    //     left: 0;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }

    &-social-sharing {
        padding: 16px;
        background-color: #212121;
        display: flex;
        flex-direction: column;
        min-width: css-min(500px, 40vw);
        max-width: css-min(500px, 80vw);
        align-items: stretch;
        position: fixed;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 40%;
        opacity: 1;
        gap: 10px;
        z-index: 500;
    }

    &-header {
        display: flex;
        justify-content: space-between;
    }

    &-title {
        color: #fff;
        font-size: 1.6rem;
        font-weight: 400;
    }

    &-close {
        position: absolute;
        right: 10px;
        top: 5px;
        color: #fff;
        font-size: 32px;
        cursor: pointer;
    }

    &-social-row {
        max-width: css-min(600px, 80vw);
        display: flex;
        overflow-x: scroll;
        justify-content: center;
        gap: 15px;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-social-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: grey;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: center;
        color: white;
        font-size: 30px;
        // margin: 10px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: #fff;
        }

        // icons onhover
        .fa-facebook-f {
            height: 100%;
            width: 100%;
            line-height: 50px;
            border-radius: 50%;

            &:hover {
                background-color: #3b5998;
            }
        }

        .fa-twitter {
            height: 100%;
            width: 100%;
            line-height: 50px;
            border-radius: 50%;

            &:hover {
                background-color: #1da1f2;
            }
        }

        .fa-whatsapp {
            height: 100%;
            width: 100%;
            line-height: 50px;
            border-radius: 50%;

            &:hover {
                background-color: #25d366;
            }
        }

        .fa-reddit-alien {
            height: 100%;
            width: 100%;
            line-height: 50px;
            border-radius: 50%;

            &:hover {
                background-color: #ff4500;
            }
        }

        .fa-linkedin-in {
            height: 100%;
            width: 100%;
            line-height: 50px;
            border-radius: 50%;

            &:hover {
                background-color: #0077b5;
            }
        }
    }

    &-copy {
        display: flex;
        justify-content: space-between;
        border: 1px solid rgbs(255, 255, 255, 0.1);
        border-radius: 2px;
        background-color: #181818;
        padding: 5px 10px;

        &-input {
            border: none;
            overflow: hidden;
            white-space: nowrap;
            background-color: #00000000;
            color: #fff;
            font-size: 14px;
            flex-grow: 1;
        }

        &-button {
            background-color: #00000000;
            border: none;
            color: #3b5998;
            font-weight: 600;
            font-size: 18px;
        }
    }
    &-closing-shadow{
        height: 100%;
        width: 100%;
        background-color: #00000090;
        z-index: 150;
        position: fixed;
        top: 0;
        left: 0;
    }
}
.fav-it{
    display: flex;
    align-items: center;
    & i {
        color: gold;
    }
}
#faved{
    background-color: gold;
    & i {
        color: white;
    }
}