@import '../../config/globalColors.scss';
.profile-banner {
  @media screen and (max-width: 800px) {
    overflow-x: hidden;
  }

  &__bg {
    min-width: 1024px;
    filter: brightness(65%);
    width: 100%;
    height: 400px;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 800px) {
      left: 50%;
      margin-left: -512px;
      height: 60%;
    }
  }

  &__container {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }

  &__shadow {
    background-image: $talkBannerOverlay;
    height: 400px;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    padding: 105px 15px 15px 15px;

    @media screen and (max-width: 800px) {
      margin-top: 60px;
      width: 100%;
      // margin-left: auto;
      // margin-right: auto;
      // height: 320px;
      // margin-bottom: 20px;
      flex-direction: column;
      padding-top: 30px;
      height: 55%;
    }
  }
  &__image {
    width: 340px;
    display: flex;
    justify-content: end;

    & img {
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 50%;
    }
    @media screen and (max-width: 800px){
      width: 100%;
      justify-content: center;
    }
  }
  &__profile{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    gap: 10px;
    & p {
      margin-bottom: 0;
    }
  }
  &__name{
    font-size: 48px;
    font-weight: 600;
    &-input{
      text-align: center;
      background: none;
      color: #fff;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      font-size: 48px;
    }
  }
  
  &__role{
    font-size: 24px;
    font-weight: 600;
    &-input{
      text-align: center;
      background: none;
      color: #fff;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      font-size: 24px;
    }
  }
  
  &__institute{
    font-size: 24px;
    font-weight: 600;
    &-input{
      text-align: center;
      background: none;
      color: #fff;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      font-size: 24px;
    }
  }
  &__edit{
    width: 250px;
    display: flex;
    align-items: flex-end;
    justify-content: end;
    gap: 10px;
    & button{
      color: #fff;
      background: rgba(255, 255, 255, 0.2);
      padding: 5px 15px;
      border-radius: 25px;
    }
    @media screen and (max-width: 800px){
      width: 100%;
      justify-content: center;
    }
  }
}
