@import '../../config/globalColors.scss';
pagination {
    &__container {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
        z-index: 100;
        @media screen and (max-width: 800px) {
            width: 100%;
        }
    }
}