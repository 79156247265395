// http://www.mademyday.de/css-height-equals-width-with-pure-css.html
// https://stackoverflow.com/questions/17621181/how-is-padding-top-as-a-percentage-related-to-the-parents-width
// https://alistapart.com/article/creating-intrinsic-ratios-for-video
.video-container {
  position: relative;
  width: 100%;

  &:before {
    content: "";
    display: block;
    // padding-top: 45%; // 9 / 16 in percent
    position: absolute;
  }

  .video {
    // position: absolute;
    // top: 50px;
    // left: 0;
    // bottom: 0;
    // right: 0;
    position: relative;

    .video-player {
      min-height: 400px;
      width: 100%;
      height: 35vw;
      // height: 100%;
      @media screen and (max-width: 800px) {
        // object-fit: contain;
        min-height: 200px;
      }
    }
  }
}

.thumbnail{
  min-height: 400px;
  width: 100%;
  height: 35vw;
  object-fit: cover;
  @media screen and (max-width: 800px) {
    // object-fit: contain;
    min-height: 200px;
  }
}

.play-btn{
  fill: #49D8DA;
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  &-right-mode{
    left: calc(50% - 24px);
  }
  // left: 32vw;
  height: 48px;
  width: 48px;
  &:hover{
    fill:#33E7EA;
    height: 50px;
    width: 50px;
    // top: 15.9vw;
    // left: 45.9vw;
    // left: 31.9vw;
    transform: translate(-1px);
  }
}
