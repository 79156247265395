.profilesetting{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: #fff;
    & form {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & div {
            width: 100%;
        }
        & button {
            width: 50%;
        }
    }
}