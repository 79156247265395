@import '../../config/globalColors.scss';

.container-2 {
  width: 40%;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  margin-top: 2px;
}

.container-2 select#search {
  width: 40px;
  height: 40px;
  background: $formDropDownBg;
  border: none;
  font-size: 14px;
  float: left;
  padding-left: 35px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: $formDropDownTextColor;
  outline: none;
  min-width: 300px;
  width: 80%;

  -webkit-transition: width 0.55s ease;
  -moz-transition: width 0.55s ease;
  -ms-transition: width 0.55s ease;
  -o-transition: width 0.55s ease;
  transition: width 0.55s ease;
}

.container-2 input#search::-webkit-input-placeholder {
  color: $formDropDownPlaceholderColor;
}

.container-2 input#search:-moz-placeholder {
  /* Firefox 18- */
  color: $formDropDownPlaceholderColor;
}

.container-2 input#search::-moz-placeholder {
  /* Firefox 19+ */
  color: $formDropDownPlaceholderColor;
}

.container-2 input#search:-ms-input-placeholder {
  color: $formDropDownPlaceholderColor;
}


.container-2 input#search:focus,
.container-2 input#search:active {
  color: $formDropDownActiveColor;
}

.container-2:hover input#search {
  color: $formDropDownActiveColor;
}