@import '../../config/globalColors.scss';
h1 {
  color: $headingTextColor;
  font-size: 2rem;
  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
  }
}
.wrap {
  margin-top: 20%;
  background-color: $belowScreenBg;
  color: #fff;
  @media screen and (max-width: 800px) {
    margin-top: 320px;
  }
  @media screen and (max-width: 1290px) {
    margin-top: 360px;
  }
  z-index: 500;
  position: absolute;
  width: 100%;
  padding: 0 3%;
}
.no-conference {
  text-align: center;
  margin-top: 30px;
  p {
    color : blue;
  }
}

.upcoming_conference {
    &-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // padding: 20px;
        font-size: 24px;
        gap: 10px;
        &-cell{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            & i {
                cursor: pointer;
            }
            &-1 {
                // flex-grow: 1;
                width: 28%;

                @media screen and (max-width: 1000px) {
                  width: 100%;
                }
            }
            &-2, &-4{
                width: 15%;

                @media screen and (max-width: 1000px) {
                  display: none;
                }
            }
            &-3{
                width: 18%;

                @media screen and (max-width: 1000px) {
                  display: none;
                }
            }
            &-5{
                width: 12%;

                @media screen and (max-width: 1000px) {
                  display: none;
                }
            }
            &-6{
                width: 12%;

                @media screen and (max-width: 1000px) {
                  display: none;
                }
            }
        }
        border-bottom: 1px solid #fff;
    }
}
.upcoming_conference-body{
    display: flex;
    flex-direction: column;
    // gap: 15px;
    &-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 18px;
        @media screen and (max-width: 1000px) {
          font-size: 14px;
        }
        gap: 10px;
        &-cell{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            // flex-grow: 1;
            padding: 5px;
            &-location{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 10px;
                position: relative;
                // flex-grow: 1;
                width: 12%;
                & i {
                    cursor: pointer;
                    position: absolute;
                    right: 10px;
                }

                @media screen and (max-width: 1000px) {
                  display: none;
                }
            }
            &-1{
                justify-content: flex-start;
                & i {
                    cursor: pointer;
                    display: none;
                    @media screen and (max-width: 1000px) {
                        display: block;
                    }
                }
                
                // flex-grow: 1;
                width: 28%;

                @media screen and (max-width: 1000px) {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    position: relative;
                    width: 100%;

                }
            }
            &-2, &-4{
                width: 15%;

                @media screen and (max-width: 1000px) {
                  display: none;
                }
            }
            &-3{
                width: 18%;

                @media screen and (max-width: 1000px) {
                  display: none;
                }
            }
            &-6 {
                width: 12%;

                @media screen and (max-width: 1000px) {
                  display: none;
                }
            }
            // &-5{
            //     width: 12%;
            // }
        }
        &-container{
            border-bottom: 0.1em solid rgba(255,255,255, 0.5);
        }
        &-hidden{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-size: 18px;
            @media screen and (max-width: 1000px) {
                font-size: 14px;
              }
            &-cell{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-grow: 1;
            }
            &-mob{
                display: none;
                padding-top: 5px;
                padding-bottom: 5px;
                @media screen and (max-width: 1000px) {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    font-size: 14px;
                    &-cell{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        flex-grow: 1;
                    }
                }
            }
        }
    }
}
.hide-mob{
    @media screen and (max-width: 1000px) {
        display: none;
    }
}