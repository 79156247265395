@import '../../config/globalColors.scss';

.collection-item {
  position: relative;

  &:hover &__text {
    // visibility: visible;
    // transition: opacity 0.62s, ease 1s;
    // opacity: 1;
    transition: margin-top 0.62s, ease 1s;
    margin-top: -25%;
  }

  &:hover &__overview {
    visibility: visible;
    transition: opacity 0.62s, ease 1s;
    opacity: 1;
  }
  
  &:hover &__addtolist {
    visibility: visible;
    transition: opacity 0.62s, ease 1s;
    opacity: 1;
  }

  &:hover &__talk-image {
    transition: opacity 0.62s, ease 1s;
    opacity: 0.5;
  }

  &__talk-image {
    width: 300px;
    z-index: 1;

    @media screen and (max-width: 800px) {
      width: 230px;
    }
  }
  &__talk-image_playlist {
    width: 300px;
    z-index: 1;
    @media (max-width: 500px) {
      width: 100% !important;
    }
    @media screen and (max-width: 800px) {
      width: 230px;
    }
  }
// edit for title position
  &__text {
    position: relative;
    // bottom: -15%;//24%;
    top: 0%;
    // left: 2%;
    // right: 1%;
    color: $cardTextColor;
    // visibility: hidden;
    // opacity: 0;
    // transition: opacity 0.62s, ease 1s;
    font-size: 10px;
    text-shadow: 3px 3px 4px black;

    @media screen and (max-width: 800px) {
      // display: none;
    }
  }

  &__title {
    color: $cardTextColor;
    font-size: 0.9rem;
    background-color: #000000a5;
    padding: 5px;
    max-width: 300px;
    text-decoration: none !important;

    @media screen and (max-width: 800px) {
      font-size: 0.6rem;
      max-width: 230px;
    }
  }

  &__title_playlist {
    color: $cardTextColor;
    font-size: 0.9rem;
    background-color: #000000a5;
    padding: 5px;
    max-width: 300px;
    text-decoration: none !important;
    @media (max-width: 500px) {
      width: 100% !important;
    }
    @media screen and (max-width: 800px) {
      font-size: 0.9rem;
      max-width: fit-content;
    }
  }
  
  &__overview {
    color: $cardTextColor;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.62s, ease 1s;

    @media screen and (max-width: 800px) {
      font-size: 0.6rem;
    }
  }
// edit for title position
  &__timestamp{
    position: absolute;
    // bottom: 5%;
    top: 60%;
    right: 2px;
    color: $cardTextColor;
    background-color: black;
    padding: 2.5px 5px;
    font-weight: 500;
    font-size: 0.6rem;
    @media screen and (max-width: 800px) {
      font-size: 0.4rem;
    }
  }

  &__addtolist {
    position: absolute;
    bottom: 5%;
    left: 1.7%;
    color: $cardTextColor;
    visibility: hidden;
    opacity: 0;
    font-size: 10px;
    text-shadow: 3px 3px 4px black;
    transition: opacity 0.62s, ease 1s;

    @media screen and (max-width: 800px) {
      display: none;
    }
    &__watch{
      color: #fff;
      text-decoration: none;
      &:hover{
        color:aliceblue;
      }
    }
  }
}
