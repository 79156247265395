.profile{
    &-body {
        // position: absolute;
        // top: 30%;
        background-color: #000;
        &__container {
            display: flex;
            gap: 25px;
            padding: 20px;
            margin-top: 400px;
        }
        &__list {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 480px;
            background-color: #282323;
            padding: 10px 15px;
            border-radius: 15px;
        }
        &__function{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 10px;
        }
        &__user{
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
        }
        &__options{
            background: none;
            border: none;
            color: #fafafa;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            white-space: nowrap;
            // min-height: 400px;
            &-selected, &:hover{
                border-left: 3px solid rgb(0, 115, 255);
                margin-left: -12px;
                padding-left: 15px;
                color: rgb(0, 115, 255);
            }
        }
        &__btn-text{
            padding-left: 10px;
            font-size: 20px;
        }
        &__area{
            flex-grow: 1;
            min-height: 480px;
            background-color: #282323;
            padding: 10px 15px;
            border-radius: 15px;
            &-tabs{
                display: flex;
                gap: 15px;
                &-btn {
                    padding: 5px 10px;
                    color: #fff;
                    background-color: #000;
                    border-radius: 10px;
                    &-selected, &:hover{
                        background-color: red;
                        border-color: red;
                    }
                }
            }
            &-body{
                width: 100%;
                height: 100%;
                padding: 10px;
            }
        }
    }
}