@import '../../config/globalColors.scss';
.banner {
  @media screen and (max-width: 800px) {
    overflow-x: hidden;
  }

  &__bg {
    min-width: 1024px;
    filter: brightness(65%);
    width: 100%;
    height: 40%;
    position: fixed;
    top: 0;
    left: 0;

    @media screen and (max-width: 800px) {
      left: 50%;
      margin-left: -512px;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }

  &__shadow {
    background-image: $talkBannerOverlay;
    padding-top: 85px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    @media screen and (max-width: 800px) {
      margin-top: 70px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      height: 320px;
      margin-bottom: 20px;
      padding-top: 20px;
    }
  }

  &__title {
    // margin-left: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    // width: 90%;
  }

  &__option {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    position: relative;

    &--newuser {
      color: $talkBannerTextColor;
      text-align: center;
      margin-right: 5px;
    }

    &--link {
      text-decoration: none;
      color: $talkBannerButtonColor;
      font-weight: 600;
      & span {
        background-color: $talkBannerButtonBg;
        padding: 1rem 2rem;
      }
    }

    &--notif{
      color: #fff;
      position: absolute;
      right: 0;
      bottom: -22%;
      width: 400px;
      @media screen and (max-width: 1000px) {
        bottom: -100%;
        width: 100%;

      }
    }
  }
}
