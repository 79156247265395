
.favourites{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: 100%;
    &-rows{
        display: flex;
        gap: 10px;
        width: 100%;
        height: 200px;
        &-items{
            position: relative;
            width: 33%;
            & img{
                position: absolute;
                width: 100%;
                height: 200px;
                object-fit: cover;
            }
            & p {
                position: absolute;
                z-index: 2;
                top: 170px;
                background-color: #0000006f;
                font-weight: 600;
                color: #fff;
                font-size: 16px;
                padding-left: 0;
                bottom: -15px;
                overflow: hidden;
                right: 0;
                left: 0;
            }
        }
    }
}