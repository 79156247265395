@import '../../config/globalColors.scss';
.search{
    &__selection{
        &_row{
            margin-top: Min(25% , 460px);
            background-color: $belowScreenBg;
            display: flex;
            gap: 2%;
            padding: 1%;
            @media screen and (max-width: 800px) {
                margin-top: 400px;
            }
            @media screen and (max-width: 576px) {
                flex-direction: column;
                gap: 32px;
                align-items: center;
            }
        }
        &_btn{
            color: #fff;
            z-index: 5;
            background: #2c5d66;
            padding: 0.5vw 1.5vw;
            @media screen and (max-width: 576px) {
                width: 80%;
            }
        }
    }
}