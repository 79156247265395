@import '../../config/globalColors.scss';

.grid-item {
  width: 100%;
  position: relative;

  &:last-child {
    .grid-item__talk-text {
      bottom: 40%;

      @media screen and (max-width: 800px) {
        bottom: 17%;
      }
    }
  }

  &__img {
    width: 100vw;
    height: auto;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &__mobile-img {
    @media screen and (min-width: 800px) {
      display: none;
    }
    @media screen and (max-width: 800px) {
      display: block;
      height: auto;
    }
  }

  &__talk-text {
    position: absolute;
    bottom: 32%;
    left: 5%;
    cursor: pointer;
    right: 5%;
    color: $bigPictureTextColor;
    font-size: 18px;
    text-shadow: 3px 3px 4px black;
    line-height: 25px;

    @media screen and (max-width: 800px) {
      font-size: 15px;
      line-height: 21px;
      bottom: 10%;
      right: 10%;
      left: 12%;
    }
  }

  &__title {
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  &__overview {
    color: $bigPictureTextColor;
  }
  &__button {
    color: $bigPictureButtonColor;
    background-color: $bigPictureButtonBg;
    padding: 0.5rem;
    border-radius: 6px;
    border-width: 0;
    text-decoration: none;
    &:hover{
      color:aliceblue;
    }
  }
  &__watch{
    color: #fff;
    text-decoration: none;
    &:hover{
      color:aliceblue;
    }
  }
}
