@import '../../config/globalColors.scss';
h1 {
  color: $headingTextColor;
  font-size: 2rem;
  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
  }
}
.wrap {
  margin-top: 20%;
  background-color: $belowScreenBg;
  @media screen and (max-width: 800px) {
    margin-top: 320px;
  }
  @media screen and (max-width: 1290px) {
    margin-top: 340px;
  }
}