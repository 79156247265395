@import '../../config/globalColors.scss';

#about-us-content-holder{
    margin-top: 80px;
    padding: 0 3% 3% 3%;
    font-family: 'Open Sanse', sans-serif;
    background-color: black;
    color: white;
    margin-bottom: 5%;
}
.aboutsection {
    color: white;
    font-family: 'Open Sans', sans-serif;
}

@keyframes animatedText {
    from {
        background-position: 0%;
    }
    to {
        background-position: 100%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.main-title {
    display: flex;
    @media screen and (max-width: 850px) {
        flex-direction: column-reverse;
        align-items: center;
    }
}

#title-text, #title-img {
    width: 50%;
    @media screen and (max-width: 850px) {
        width: 90%;
    }
}

#title-intro {
    font-size: 24px;
    line-height: 1.6;
    text-align: justify;
}

#disseminating, #knowledge {
    font-size: 4vw;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.5vw;
    background-image: linear-gradient(315deg, #647dee, #7f53ac, #5f72be, #9921e8, #b621fe, #1fd1f9);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 150%;
    background-position: -150%;
    animation: animatedText 5s infinite alternate-reverse;
    @media screen and (max-width: 850px) {
        font-size: 8vw;
    }
}

#knowledge {
    letter-spacing: 1.05vw;
    border: 1px solid white;
    width: fit-content;
    border-radius: 2vw;
    padding: 2%;
}

#title-img-source {
    max-width: 100%;
    max-height: 100%;
    margin-top: 5%;
    margin-left: 2%;
}

.explore-whole, .engage-whole {
    display: flex;
    margin-top: 8%;
    @media screen and (max-width: 850px) {
        flex-direction: column-reverse;
        align-items: center;
    }
}
.connect-whole{
    display: flex;
    margin-top: 8%;
    @media screen and (max-width: 850px) {
        flex-direction: column;
        align-items: center;
    }
}

.explore, .connect, .engage {
    width: 50%;
    @media screen and (max-width: 850px) {
        width: 90%;
    }
}

.dash {
    background-image: linear-gradient(315deg, #4dccc6, #a29bfe, #009ffd, #2a2a72, #1e3b70, #29539b, #37d5d6, #36096d);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 150%;
    background-position: -150%;
    animation: animatedText 3s infinite alternate-reverse;
}

.explore-title, .connect-title, .engage-title {
    font-size: 4vw;
    background-image: linear-gradient(315deg, #4dccc6, #a29bfe, #009ffd, #2a2a72, #1e3b70, #29539b, #37d5d6, #36096d);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 150%;
    background-position: -150%;
    animation: animatedText 3s infinite alternate-reverse;
    @media screen and (max-width: 850px) {
        font-size: 8vw;
    }
}

.explore-content, .connect-content, .engage-content {
    margin-top: 5%;
    line-height: 1.6;
    font-size: 24px;
    text-align: justify;
}

.explore-img, .connect-img, .engage-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    @media screen and (max-width: 850px) {
        flex-direction: column-reverse;
        align-items: center;
        width: 90%;
    }
}

.source-img {
    height: css-min(40vh, 40vw);
    max-width: 100%;
    object-fit: cover;
}

.about-us-container {
    margin-top: 8%;
}

.about-us-title {
    font-size: 5vw;
    text-align: center;
    margin-bottom: 3%;
    letter-spacing: 1.6vw;
    background-image: linear-gradient(315deg, #647dee, #7f53ac, #5f72be, #9921e8, #b621fe, #1fd1f9);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 150%;
    background-position: -150%;
    animation: animatedText 5s infinite alternate-reverse;
    @media screen and (max-width: 850px) {
        font-size: 10vw;
    }
}

.about-us-whole {
    display: flex;
    @media screen and (max-width: 850px) {
        flex-direction: column;
        align-items: center;
        gap: 2vh;
    }
}

.about-us-img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 850px) {
        width: 90%;
    }
}

.about-us-source-img {
    width: 75%;
}

.about-us-content {
    width: 50%;
    font-size: 25px;
    line-height: 1.6;
    @media screen and (max-width: 850px) {
        width: 90%;
    }
}
.team-para {
    font-size: 25px;
    line-height: 1.6;
}
.team-title {
    text-align: center;
    font-size: 5vw;
    letter-spacing: 1.6vw;
    margin: 0 auto;
    margin-top: 8%;
    margin-bottom: 2%;
    background-image: linear-gradient(315deg, #647dee, #7f53ac, #5f72be, #9921e8, #b621fe, #1fd1f9);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 150%;
    background-position: -150%;
    animation: animatedText 5s infinite alternate-reverse;
    @media screen and (max-width: 850px) {
        font-size: 10vw;
    }
}

.member-left-row {
    display: flex;
    justify-content: start;
    margin-top: -10vh;
    @media screen and (max-width: 850px) {
        margin-top: 5vh;
        justify-content: center;
    }
}

.member-right-row {
    display: flex;
    justify-content: end;
    margin-top: -20vh;
    @media screen and (max-width: 850px) {
        margin-top: 5vh;
        justify-content: center;
    }
}

.member-1-whole, .member-2-whole, .member-3-whole, .member-4-whole  {
    display: flex;
    /* background-color: darkblue; */
    border-radius: 5vw 5vw 5vw 5vw;
    padding: 2%;
    gap: 3%;
    width: fit-content;
    // cardTest
    @media screen and (max-width: 576px) {
        flex-direction: column;
        gap: 1px;
        align-items: center;
    }
}

.member-1-whole {
    background: linear-gradient(-45deg, #4dccc6, #a29bfe, #009ffd, #2a2a72, #1e3b70, #29539b, #37d5d6, #36096d);
    background-size: 400%;
    background-position: -400%;
    animation: animatedText 20s infinite alternate-reverse;
}

.member-2-whole {
    background: linear-gradient(-45deg, #009ffd, #2a2a72, #1e3b70, #29539b, #37d5d6, #36096d, #4dccc6, #a29bfe);
    background-size: 400%;
    background-position: -400%;
    animation: animatedText 15s infinite alternate-reverse;
}

.member-3-whole {
    background: linear-gradient(-45deg, #1e3b70, #29539b, #37d5d6, #36096d, #4dccc6, #a29bfe, #009ffd, #2a2a72);
    background-size: 400%;
    background-position: -400%;
    animation: animatedText 15s infinite alternate-reverse;
}

.member-4-whole {
    background: linear-gradient(-45deg, #37d5d6, #36096d, #4dccc6, #a29bfe, #009ffd, #2a2a72, #1e3b70, #29539b);
    background-size: 400%;
    background-position: -400%;
    animation: animatedText 15s infinite alternate-reverse;
}

.member-2-whole, .member-4-whole {
    justify-content: end;
}

#member-1-id, #member-2-id, #member-3-id, #member-4-id {
    width: 45%;
    @media screen and (max-width: 850px) {
        width: 80%;
    }
}

.member-1, .member-2, .member-3, .member-4 {
    width: 50%;
    margin-left: 4%;
    // cardTest
    @media screen and (max-width: 576px) {
        width: 90%;
    }
}

.member-1-img, .member-2-img, .member-3-img, .member-4-img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // cardTest
    @media screen and (max-width: 576px) {
        width: 95%;
    }
}

.member-img {
    width: 100%;
    height: 30vh;
    min-height: 100%;
    object-fit: cover;
    border-radius: 5%;
}

.member-1-name, .member-2-name, .member-3-name, .member-4-name {
    font-size: 2.5vw;
    @media screen and (max-width: 850px) {
        font-size: 6vw;
    }
}

.member-1-position, .member-2-position, .member-3-position, .member-4-position {
    font-size: 16px;
}

.member-1-content, .member-2-content, .member-3-content, .member-4-content {
    font-size: 12px;
    text-align: justify;
    line-height: 1.6;
}

.lower-name-dash {
    text-align: right;
    margin-right: 35%;
}

.faq-title {
    text-align: center;
    margin-top: 8%;
    margin-bottom: 5%;
    font-size: 5vw;
    letter-spacing: 1.6vw;
    background-image: linear-gradient(315deg, #647dee, #7f53ac, #5f72be, #9921e8, #b621fe, #1fd1f9);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 150%;
    background-position: -150%;
    animation: animatedText 5s infinite alternate-reverse;
    @media screen and (max-width: 850px) {
        font-size: 25px;
    }
}

.accordion {
    background-color: black;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 25px;
    transition: 0.4s;
}
  
.active, .accordion:hover {
    background-color: black;
}
  
.accordion:after {
    content: '>';
    color: white;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}
  
.active:after {
    content: "v";
}
  
.panel {
    padding: 0 18px;
    background-color: black;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.faq-content {
    color: white;
    line-height: 1.6;
    font-size: 22px;
    text-align: justify;
}

// #anchor {
//     text-decoration: none;
//     font-weight: bolder;
//     color: #2196f3;
// }

li {
    margin-bottom: 3%;
    line-height: 1.6;
    font-size: 20px;
    text-align: justify;
}

.contact-us {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 850px) {
        flex-direction: column;
        align-items: center;
        gap: 2vh;
    }
}

.contact-us-title {
    width: 100%;
    text-align: center;
    margin-top: 8%;
    margin-bottom: 5%;
    font-size: 5vw;
    letter-spacing: 1.6vw;
    background-image: linear-gradient(315deg, #647dee, #7f53ac, #5f72be, #9921e8, #b621fe, #1fd1f9);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 150%;
    background-position: -150%;
    animation: animatedText 5s infinite alternate-reverse;
    @media screen and (max-width: 850px) {
        font-size: 10vw;
    }
}

.contact-us-img-container {
    display: flex;
    justify-content: left;
    align-items: center;
    max-width: 850px;
    @media screen and (max-width: 850px) {
        justify-content: center;
    }
}

#contact-us-img {
    width: 75%;
    max-width: 100%;
    object-fit: cover;
}

.contact-us-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.contact-content {
    text-align: justify;
    font-size: 24px;
    line-height: 1.6;
}

.contact-button {
    margin-top: 8%;
    align-self: flex-end;
}

#mail-button {
    background-color: #2196f3;
    color: white;
    text-align: center;
    line-height: 1.6;
    letter-spacing: 0.1vw;
    border-radius: 2rem;
    border-width: 0;
    // height: 6vh;
    // width: 12.5vw;
    padding: 8px 16px;
}
#anchor, #anchor:link, #anchor:visited, #anchor:active {
    text-decoration: none;
    font-weight: bolder;
    color: #2196f3;
}

#anchor:hover, #anchor:focus {
    text-decoration: none;
    font-weight: bolder;
    color: white;
}