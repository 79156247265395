@import '../../config/globalColors.scss';

.header {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  background-color: $headerBg;
  position: fixed;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    // height: 120px;
    height: 60px;
  }

  &__logo-box {
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      margin: 0 auto;
    }
  }

  &__logo {
    height: 100%;
    width: 120px;
    margin-left: 20px;
    cursor: pointer;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      margin: unset;
    }
  }

  &__options-primary{
    display: flex;
    flex-direction: row;
    justify-content: start;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &__dropdownOption{
    color: $headerText;
    white-space: nowrap;
    margin-top: 5px;
    font-weight: 600;
    @media screen and (max-width: 768px) {
      display: none;
    }
    &:hover {
      color: $headerTextHover;
      transition: 0.4s all;
    }
    text-decoration: none;
  }
  &__options-secondary {
    display: flex;
    flex-direction: row;
    justify-content: end;
    // gap: 10px;
    @media screen and (max-width: 768px) {
      display: none;
    }
    & div{
      display: flex;
      gap: 5px;
    }
  }
  

  &__options {
    width: 88%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1vw;

    @media screen and (max-width: 768px) {
      text-align: center;
      width: 100%;
      display: none;
      flex-direction: row;
      // margin-bottom: 25px;
      // height: 200px;
      justify-content: center;
    }
    &-social,&-social-in{
      color: #fff;
      height: 32px;
      font-size: 28px;
      line-height: 36px;
      width: 32px;
      text-align: center;
      background-color: #0A58CA;
      border-radius: 16px;
      justify-self: end;
      &:hover{
        text-decoration: none;
        color: #fff;
      }
    }
    &-social-in{
      line-height: 32px;
      font-size: 22px
    }
  }

  &__option {
    padding: 10px 0.75vw;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 550;
    background-color: transparent;
    cursor: pointer;
    color: $headerText;
    text-decoration: none;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;

    @media screen and (max-width: 768px) {
      padding: 5px 6px;
      font-size: 13px;
    }

    &:hover {
      color: $headerTextHover;
      transition: 0.4s all;
    }

    &--signin {
      margin-right: 1.5vw;
      @media screen and (max-width: 768px) {
        margin: unset;
      }
    }
    &--trial {
      margin-right: 1.5vw;
      background-color: $trialButtonBg;
      @media screen and (max-width: 768px) {
        margin: unset;
      }
    }
    &--logout {
      margin-right: 1.5vw;
      margin-left: 1vw;
      background-color: $trialButtonBg;
      @media screen and (max-width: 768px) {
        margin: unset;
      }
    }
    &--profile {
      text-decoration: none;
    }
    &-username{
      color: #fff;
      margin-right: 1.5vw;
      display: flex;
      align-items: center;
      gap: 10px;
      @media screen and (max-width: 768px) {
        display: none;
      }
      & div{
        display: flex;
        gap: 5px;
      }
    }

    &--signout {
      display: inline;
      margin-right: 15px;
      @media screen and (max-width: 768px) {
        margin: unset;
      }
    }
  }

  &__searchbar {
    margin-left: auto;
    margin-right: 15px;

    @media screen and (max-width: 768px) {
      margin: unset;
      position: absolute;
      top: 65%;
      margin: 0 auto;
    }
  }

  &__nav-menu-icon {
    @media screen and (min-width: 768px) {
      display: none;
    }
    @media screen and (max-width: 768px) {
      display: inline-block;
      font-size: 25px;
      color: $navMenuIconColor;
      position: absolute;
      top: auto;
      left: 2%;
      padding: 10px;
      transition: all 0.3s ease;
      cursor: pointer;

      &:active {
        transform: scale(1.1);
      }
    }
  }
}
.brandLogo {
  display: flex;
  justify-content: center;
  align-items: center;
}
#seminare-logo {
  @media screen and (max-width: 768px) {
    width: 70%;
    padding-block: auto;
  }
}
.brandLogo img{
  height: auto;
  width: 17vw;
}

.selected{
  position: relative;
}

.selected::before {
  content: "";
  position: absolute;
  background-color: $selectedPagePointer;
  height: 4px;
  margin-left: -20px;
  margin-top: -26px;
  top: 5px;
  z-index: 333;
  left: 50%;
  width: 2.75vw;
}
// .selected::before {
//   content: "";
//   position: absolute;
//   background-color: $selectedPagePointer;
//   height: 4px;
//   margin-left: -20px;
//   margin-top: -26px;
//   top: 25px;
//   z-index: 333;
// }

// .home::before{
//   left: 50%;
//   width: 70%;
// }
// .conference::before{
//   left: 19.75vw;
//   width: 5.625vw;
// }
// .talk::before{
//   left: 26.875vw;
//   width: 2.75vw;
// }
// .about::before{
//   left: 30.625vw;
//   width: 2.875vw;
// }
// .browse::before{
//   left: 34.6875vw;
//   width: 5vw;
// }
.dropdown-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  background-color: $headerAboutDropdownBg;
  padding: 0.25vw;
  // min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 0.3rem;
  text-shadow: none;
  @media screen and (max-width: 768px) {
    display: none;
  }
  &-browse{
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    min-width: 500px;
    min-height: 500px;
    background-color: $browseDropdownBg;
  }
  &-row{
    display: flex;
    flex-direction: row;
  }
}