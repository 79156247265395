.watch-grid {
  // display: grid;
  grid-template: auto auto auto 1fr / minmax(0, 1280px) 402px;
  justify-content: center;
  padding-top: 80px;
  column-gap: 24px;
  grid-row-gap: 8px;
  max-width: 100vw;

  .video {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    &-right-mode{
      // test
      max-width: 70vw;
      // test end
    }
  }
  .metadata {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  .video-info-box {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
  .related-videos {
    grid-column: 2 / 3;
    grid-row: 1 / span 4;
  }
  .comments {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }
}

// 1280px (max-width of video column) + 402px (width of side bar) + 3 * 24px (empty space on the left, right and between the two columns)
@media (max-width: 1754px) {
  .watch-grid {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.writing-area{
  &-right-mode{
    // test
    position: absolute;
    top: 80px;
    right: 10px;
    width: 25vw;
    min-height: 39vw;
    margin-top: 0;
    // test end
  }
  margin-top: 3vw;
  margin-bottom: 1vw;
  position: relative;
  // width: 100%;
  background-color: #fff;
  padding: 0.3rem 0;
  box-shadow: 0 5px 50px rgba(#56A5EC, .15);
  &-tab{
    display: flex;
    padding: 0 0.3rem;
    &-option{
      padding: .75rem 1.5rem;
      cursor: pointer;
      width: 49%;
      max-width: 320px;
      color: black;
      font-weight: 700;
      font-size: 16px;
      background-color: #e6e6e6;
      text-align: center;
      &-open{
        background-color: #1FBAB8;
        color: white;
      }
    }
  }
  &-inputs{
    width: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    textarea {
      width: 100%;
      padding: 1.5rem;
      font-size: 18px;
      // line-height: 2vw;
      border-bottom-width: 1px;
    }
    &-row{
      display: flex;
      width: 100%;
      align-content: center;
      padding-top: 0.3rem;
      &-dp{
        width: 2.6vw;
        height: 2.6vw;
        margin-right: 0.5rem;
        background-color: rgb(211, 214, 219);
        padding: 0.3rem;
      }
      &-column{
        flex-grow: 1;
      }
      &-author{
        font-size: 20px;
        color: #56A5EC;
        line-height: 2vw;
      }
      &-time{
        font-size: 20px;
        color: grey;
        line-height: 2vw;
        font-weight: 600;
        margin-left: 0.5rem;
      }
    }
    &-comment {
      flex-grow: 1;
      font-size: 18px;
      &-submit{
        margin-top: 1vw;
        padding: 10px 16px;
        // width: 10vw;
        text-align: center;
        font-size: 24px;
        background-color: #56A5EC;
        color: white;
        cursor: pointer;
      }
    }
  }
  &-comment-notes-disabled{
    width: 100%;
    height: 100%;
    background-color: #000000a5;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    padding: 1vw;
    color: #e6e6e6;
  }
}

.sticky{
  position: fixed;
  background-color: darkgray;
  &-right-mode{
    background-color: burlywood;
  }
  right: 0;
  bottom: 5%;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  padding: 10px 16px;
  width: 80px;
  cursor: pointer;
  &:hover{
    width: 90px;
  }
}
.editorClassName{
  color: black;
  border: 1px solid black;
  padding: 0 0.5rem;
  height: 22vw !important;
  overflow-y: auto;
}
.wrapperClassName{
  flex-grow: 1;
  width: 100%;
}