$headerBg:#00000063;
$headerAboutDropdownBg: grey;
$headerText: #e5e5e5;
$headerTextHover: #a9a9a9;
$trialButtonBg: red;
$navMenuIconColor: white;
$logoColor: white;
$selectedPagePointer: #bb0a12;
$browseDropdownBg: grey;

$headingTextColor: white;
$belowScreenBg: black;

$inputBg: #141414;
$inputTextColor: #262626;
$inputPlaceholderColor: #65737e;
$inputSearchIconColor: #e5e5e5;
$inputSearchIconHoverColor: #93a2ad;
$inputSearchIconActiveColor: white;

$mobileNavbarBg: black;
$textColor: white;
$exitIconColor: white;

$formDropDownBg: #141414;
$formDropDownTextColor: #fff;
$formDropDownPlaceholderColor: #65737e;
$formDropDownActiveColor: white;

$bigPictureArrow: white;
$bigPictureTextColor: #fff;
$bigPictureButtonColor: white;
$bigPictureButtonBg: red;

$cardTextColor: #fff;
$cardArrow: white;

$talkBannerOverlay: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
$talkBannerTextColor: white;
$talkBannerButtonColor: black;
$talkBannerButtonBg: white;


// Watch css variables
$header-nav-height: 64px;
$sidebar-left-width: 17rem;
$grey: #888888;
$red: #ff0002;
$text-color: white;
$avatar-diameter: 48px;
$avatar-margin: 10px;

@function css-function( $function, $values... ) {
    @return 
        $function
        + unquote( '(' )
        + $values
        + unquote( ')' )
    ;
}

@function css-min( $values... ) {
    @return css-function( min, $values );
    // @return min($values)
}

