@import '../../config/globalColors.scss';

.container-2 {
  width: 48%;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  margin-top: 2px;
}

.container-2 input#search {
  width: 40px;
  height: 40px;
  background: $inputBg;
  border: none;
  font-size: 14px;
  float: left;
  color: $inputTextColor;
  padding-left: 35px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  outline: none;
  min-width: 300px;
  width: 80%;

  -webkit-transition: width 0.55s ease;
  -moz-transition: width 0.55s ease;
  -ms-transition: width 0.55s ease;
  -o-transition: width 0.55s ease;
  transition: width 0.55s ease;
}

.container-2 input#search::-webkit-input-placeholder {
  color: $inputPlaceholderColor;
}

.container-2 input#search:-moz-placeholder {
  /* Firefox 18- */
  color: $inputPlaceholderColor;
}

.container-2 input#search::-moz-placeholder {
  /* Firefox 19+ */
  color: $inputPlaceholderColor;
}

.container-2 input#search:-ms-input-placeholder {
  color: $inputPlaceholderColor;
}

.container-2 .search-icon {
  position: absolute;
  top: 0%;
  left: 0%;
  margin-left: 11px;
  margin-top: 11px;
  z-index: 1;
  color: $inputSearchIconColor;
}

.container-2 input#search:focus,
.container-2 input#search:active {
  color: $inputSearchIconActiveColor;
}

.container-2:hover input#search {
  color: $inputSearchIconActiveColor;
}

.container-2:hover .search-icon {
  color: $inputSearchIconHoverColor;
}

#search-btn {
  min-width: 100px;
  height: 40px;
  border-radius: 10px;
  margin-left: 2%;
}